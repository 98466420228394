.amentiies-page {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.amentiies-page .div {
  background-color: #000000;
  height: 2001px;
  position: relative;
  width: 1024px;
}

.amentiies-page .icon-back {
  height: 99px;
  left: 50px;
  position: absolute;
  top: 50px;
  width: 107px;
}

.amentiies-page .hottub-button {
  height: 169px;
  left: 50px;
  position: absolute;
  top: 1215px;
  width: 898px;
}

.amentiies-page .overlap-group {
  background-color: #1e1e1e;
  height: 169px;
  position: relative;
  width: 894px;
}

.amentiies-page .text-wrapper {
  color: #ffffff;
  font-family: "Lalezar-Regular", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 93px;
  letter-spacing: 0.15px;
  line-height: normal;
  position: absolute;
  top: 50px;
  width: 540px;
}

.amentiies-page .text-wrapper-2 {
  color: #ffffff;
  font-family: "Lalezar-Regular", Helvetica;
  font-size: 96px;
  font-weight: 400;
  height: 78px;
  left: 744px;
  letter-spacing: 9.65px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 88px;
}

.amentiies-page .camer-button {
  height: 169px;
  left: 50px;
  position: absolute;
  top: 1434px;
  width: 898px;
}

.amentiies-page .amenity-header {
  height: 98px;
  left: 64px;
  position: absolute;
  top: 288px;
  width: 897px;
}

.amentiies-page .section-title {
  color: #ffffff;
  font-family: "Lalezar-Regular", Helvetica;
  font-size: 96px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.15px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 895px;
}

.amentiies-page .wifi-button {
  height: 649px;
  left: 50px;
  position: absolute;
  top: 464px;
  width: 894px;
}

.amentiies-page .overlap {
  height: 649px;
  position: relative;
  width: 898px;
}

.amentiies-page .dropdown-back {
  background-color: #565656;
  height: 526px;
  left: 0;
  position: absolute;
  top: 123px;
  width: 894px;
}

.amentiies-page .header {
  background-color: transparent;
  height: 169px;
  left: 0;
  position: absolute;
  top: 0;
  width: 898px;
}

/* New Code */
.centered-accordian-div {
  position: absolute; /* Needed for absolute positioning */
  top: 450px;
  left: 64px;
}

