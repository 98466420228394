@font-face {
  font-family: "Arial Black-Regular";
  src: url("./font/ArialBlack.ttf") format("truetype");
}
@font-face {
  font-family: "Lalezar-Regular";
  src: url("./font/Lalezar-Regular.ttf") format("truetype");
}

body {
  user-select: none;
  background-color: black;
}

@media only screen and (max-width: 500px) {
  .div {
    zoom: 0.375; /* Adjust the zoom level as needed */
  }
}

@media only screen and (max-width: 600px), (max-device-width: 8in) {
  .div {
    zoom: 0.5; /* Adjust the zoom level as needed */
  }
}
