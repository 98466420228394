.rules-page {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.rules-page .div {
    background-color: #000000;
    height: 1701px;
    position: relative;
    width: 1024px;
}

.rules-page .rules-header {
    height: 98px;
    left: 79px;
    position: absolute;
    top: 449px;
    width: 897px;
}

.rules-page .section-title {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 96px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 895px;
}

.rules-page .icon-back {
    height: 100px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 108px;
}

.rules-page .icon-home {
    height: 182px;
    left: 50px;
    position: absolute;
    top: 267px;
    width: 192px;
}

.rules-page .rules-description {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 32px;
    font-weight: 300;
    left: 79px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 616px;
    width: 847px;
}
