.home-page {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 7vh;
}

.home-page .div {
    background-color: #000000;
    height: 1366px;
    position: relative;
    width: 1024px;
}

.home-page .home-buttons {
    height: 396px;
    left: 65px;
    position: absolute;
    top: 827px;
    width: 894px;
}

.home-page .local-button {
    height: 169px;
    left: 0;
    position: absolute;
    top: 227px;
    width: 896px;
}

.home-page .overlap-group {
    background-color: #1e1e1e;
    border-radius: 40px;
    height: 169px;
    position: relative;
    width: 894px;
}

.home-page .text-wrapper {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 93px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 227px;
}

.home-page .vector {
    height: 65px;
    left: 797px;
    position: absolute;
    top: 52px;
    width: 37px;
}

.home-page .info-button {
    height: 169px;
    left: 0;
    position: absolute;
    top: 0;
    width: 896px;
}

.home-page .overlap {
    background-color: #1a1a1a;
    border-radius: 40px;
    height: 169px;
    position: relative;
    width: 894px;
}

.home-page .text-wrapper-2 {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 79px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 483px;
}

.home-page .title-group {
    height: 144px;
    left: 65px;
    position: absolute;
    top: 576px;
    width: 898px;
}

.home-page .overlap-2 {
    height: 144px;
    position: relative;
    width: 894px;
}

.home-page .text-wrapper-3 {
    color: #b3b3b3;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 71px;
    width: 894px;
}

.home-page .text-wrapper-4 {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 70px;
    font-weight: 400;
    height: 110px;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    width: 894px;
}

.home-page .cover-image {
    height: 497px;
    left: 65px;
    object-fit: cover;
    position: absolute;
    top: 45px;
    width: 894px;
}
