.checkout-page {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.checkout-page .div {
    background-color: #000000;
    height: 2261px;
    position: relative;
    width: 1024px;
}

.checkout-page .checkout-section {
    height: 1695px;
    left: 68px;
    position: absolute;
    top: 471px;
    width: 880px;
}

.checkout-page .checkout-header {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 96px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 876px;
}

.checkout-page .description-box {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 34px;
    left: 6px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 162px;
    width: 829px;
}

.checkout-page .icon-exit {
    height: 191px;
    left: 50px;
    position: absolute;
    top: 279px;
    width: 192px;
}

.checkout-page .icon-back {
    height: 100px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 108px;
}
