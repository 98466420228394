.contact-page {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.contact-page .div {
    background-color: #000000;
    height: 1366px;
    position: relative;
    width: 1024px;
}

.contact-page .icon-back {
    height: 100px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 108px;
}

.contact-page .contact-header {
    height: 98px;
    left: 64px;
    position: absolute;
    top: 288px;
    width: 897px;
}

.contact-page .section-title {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 96px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 895px;
}

.contact-page .contact-section {
    background-color: #1e1e1e;
    border-radius: 40px;
    height: 254px;
    left: 64px;
    position: absolute;
    top: 670px;
    width: 925px;
}

.contact-page .contact-description {
    height: 151px;
    left: 51px;
    position: relative;
    top: 38px;
    width: 484px;
}

.contact-page .contact-name {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 64px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 371px;
}

.contact-page .contact-number {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 64px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 15px;
    white-space: nowrap;
    width: 480px;
}

.contact-page .text-wrapper {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 64px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 420px;
    width: 895px;
}
