.nav-page {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.nav-page .div {
    background-color: #000000;
    height: 1366px;
    position: relative;
    width: 1024px;
}

.nav-page .button-group {
    height: 832px;
    left: 57px;
    position: absolute;
    top: 327px;
    width: 910px;
}

.nav-page .rules-button {
    height: 169px;
    left: 7px;
    position: absolute;
    top: 442px;
    width: 896px;
}

.nav-page .overlap-group {
    background-color: #1a1a1a;
    border-radius: 40px;
    height: 169px;
    position: relative;
    width: 894px;
}

.nav-page .text-wrapper {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 79px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 483px;
}

.nav-page .vector {
    height: 65px;
    left: 797px;
    position: absolute;
    top: 52px;
    width: 37px;
}

.nav-page .amenities-button {
    height: 169px;
    left: 0;
    position: absolute;
    top: 221px;
    width: 896px;
}

.nav-page .checkout-button {
    height: 169px;
    left: 0;
    position: absolute;
    top: 0;
    width: 896px;
}

.nav-page .overlap-group-2 {
    background-color: #1e1e1e;
    border-radius: 40px;
    height: 169px;
    position: relative;
    width: 894px;
}

.nav-page .text-wrapper-2 {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 93px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 540px;
}

.nav-page .contact-button {
    height: 169px;
    left: 16px;
    position: absolute;
    top: 663px;
    width: 894px;
}

.nav-page .local-button {
    height: 169px;
    width: 896px;
}

.nav-page .text-wrapper-3 {
    color: #ffffff;
    font-family: "Lalezar-Regular", Helvetica;
    font-size: 48px;
    font-weight: 400;
    left: 60px;
    letter-spacing: 0.15px;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 609px;
}

.nav-page .icon-chevron-circle {
    height: 100px;
    left: 50px;
    position: absolute;
    top: 50px;
    width: 108px;
}
